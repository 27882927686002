import { Box, Heading, Text, Button } from 'theme-ui'
import Img from 'gatsby-image'
import React from 'react'
import {
    handleContactLabel,
} from "./helpers"
import { navigate } from 'gatsby-link'
import {
    handleLinkLanguage
  } from "./helpers"

const Packaging = ({ 
    packagingTitle,
    packagingDescription,
    packingOptions,
    currentLang,
 }) => {

    return (
        <Box
            sx={{
                width: '100%',
                dislpay: 'flex',
                height: '100%',
                px: [0,0,7],
                flexDirection: 'column',
                py: [5,2,6],
                bg: 'secondaryBlue',
            }}>

            <Box
                sx={{
                    display: 'flex',
                    height: '100%',
                    widht: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: [2,2,3], 
                    textAlign: ['center','center','inherit'],
                    

                }}>
                <Heading className='splittext'
                    sx={{
                        fontSize: [6,8,7],
                        color: 'white',
                        pb: [2,2,0],
                        mb:[1,1,3],

                    }}>
                    {packagingTitle}
                </Heading>

                <Text className='splittext'
                    sx={{
                        textAlign: 'center',
                        fontSize: [5,6,5],
                        color: 'white',
                        width: ['100%','100%','60%'],


                    }}>
                    {packagingDescription}
                </Text>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    
                }}>

                {packingOptions.map(x => <Box
                    sx={{
                        display: 'flex',
                        flexDirection: ['column','column','row'],
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        mt: [5,5,'60px'],
                        alignItems:['center','center','inherit'],
                        justifyContent:['center','center','inherit'],
                        textAlign: ['center','center','inherit'],
                  
                    }}>
                    {x && x.image && x.image.fluid && <Box sx={{
                        width: ['100%', '50%'],
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}><Box
                        sx={{
                            width: ['100%','500px','350px'],
                            height: ['200px','320px','250px'],
                            borderRadius: '20px',
                            overflow: "hidden",
                            '& .gatsby-image-wrapper': {
                                width: '100%',
                                height: '100%',
                            }
                        }}>
                        <Img fluid={x.image.fluid}></Img>
                    </Box></Box>}
                    <Box
                        sx={{
                            
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            pl: [0,0,'50px'],
                            width: ['90%', '90%','50%'],
                            
                            

                        }}>
                        <Heading 
                            sx={{
                                fontSize: [5,6,5],
                                color: 'white',
                                pt: [3,3,0],
                                mb: ['auto','auto',2], 
                            }}>
                            {x.title}
                        </Heading>
                        <Text
                            sx={{
                                fontSize: [4,5,4],
                                color: 'white',
                                pt: [3,3,0],
                                mb: [3,3,4],
                                'ul': {
                                    listStyle: 'none',
                                    pl: 0,
                                    ml: 0,
                                },
                                'li': {
                                    pl: 0,  
                                    fontSize: 3,
                                    mb: 0,
                                }
                            }}>
                                <div dangerouslySetInnerHTML={{__html: x.description}}/>
                        </Text>
                        <Button
                            onClick={() => navigate(handleLinkLanguage("/contact", currentLang))}
                            sx={{
                                display:'flex',
                                backround: '#C4C4C4',
                                zIndex: 2,
                                borderRadius: '20px',
                                color: 'primaryBlue',
                                alignSelf: 'flex-start',
                                margin: ['auto','auto',0],

                            }}>
                            {handleContactLabel(currentLang)}
                        </Button>
                    </Box>

                </Box>)}

            </Box>
            {/* <Box
                sx={{
                    width:'100%',
                    height: ['100%','100%','350px'],
                    display:'flex',
                    pt: '100px', 
                }}>
                    <Box
                    sx={{
                        alignItems:['center','center','inherit'],
                        justifyContent:['center','center','inherit'],
                        textAlign: ['center','center','inherit'],
                        flexDirection: 'column',
                        width:'100%',
                        height: '100%',
                        display: 'flex',   
                        alignItems: 'center',
                        margin:['auto','auto', 0],
                      
                        
                    }}>
                        <Heading className='splittext'
                            sx={{
                                fontSize: [6,7,6],
                                color: 'white',
                                pb: [3,3,0],
                                mb: ['auto','auto',2], 
                            }}>
                                {specificPackageTitleEn}
                            </Heading>
                        <Text className='splittext'
                            sx={{
                                fontSize:[5,5,2],
                                color:'white', 
                                mb: [3,3,4], 
                            }}>
                                {specificPackageDescriptionEn}
                            </Text>
                            <Button
                            sx={{
                                width: [200,200,'143px'],
                                height: [75,75,'43px'],
                                backround: '#C4C4C4',
                                zIndex: 2,
                                borderRadius: '20px',
                                color: 'primaryBlue',
                                mb: [5,5,0],

                            }}>
                        </Button>
                     </Box>
            </Box> */}

        </Box>

    )
}

export default Packaging
