import React from 'react'
import { Box, Heading, Text} from 'theme-ui'
import Img from 'gatsby-image'

const Logistics = ({ 
    logisticsTitle,
    logisticsDescription,
    logisticsImage,
 }) => {

    return (
        <Box
            sx={{
                width: '100%', 
                height: '100%', 
                display: 'flex', 
    
            }}>
                <Box
                    sx={{
                        width: '100%', 
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                    }}>
                        {/* <Box
                            sx={{
                                width:'100%', 
                                height: ['500px','500px','70vh'], 
                                display: 'flex',
                            
                                

                                '& .gatsby-image-wrapper': {
                                    width: '100%',
                                    height: '100%',
                                }
                            }}>
                                <Img fluid={logisticsImage.fluid}/>

                        </Box> */}
                        <Box 
                            sx={{
                                width: '100%', 
                                height: '100%', 
                                display: 'flex', 
                                
                                flexDirection: 'column',
                                mt: [5,6,'200px'],
                                px: [4,5,0],
                                justyContent: 'center', 
                                alignItems: 'center',
                                textAlign:'center',
        
                            }}>
                                <Heading className='splittext'
                                    sx={{
                                        fontSize: [6,8,7], 
                                        color: 'white', 
                                       

                                    }}>
                                        {logisticsTitle}
                                </Heading>
                                <Text className='splittext'
                                    sx={{
                                        
                                        textAlign: 'center',
                                        pt: '20px',
                                        pb: [5,6,'200px'],
                                        fontSize: [5,6,5], 
                                        color: 'white', 
                                        width: ['100%','100%','40%'],
                                    }}>
                                        {logisticsDescription}
                                    </Text>
                                


                        </Box>
                </Box>
        </Box>

    )
}

export default Logistics 
