import React, {useEffect} from 'react'
import { Box, Text, Heading } from 'theme-ui'


const Partners = ({ 
    partnersTitle,
    partnersDescription,
    partnersTypes,
 }) => {
    
    return (
        <Box
        id="partnerListBox"
            sx={{
                width: '100%',
            }}>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: ['column', 'column', 'row'],
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: [5, 0, 200],
                    mt: [0, 0, 200],
                    px: [0, 3, 7],

                }}>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textAlign: ['center', 'center', 'inherit'],
                    }}>
                    <Box
                        sx={{
                            width: ['100%', '100%', '70%'],
                        }}>
                        <Heading className='splittext'
                            sx={{

                                fontSize: [6, 8, 7],
                                color: 'white',
                                pb: 3,


                            }}>
                            {partnersTitle}
                        </Heading>
                        <Text as="p" className='splittext'
                            sx={{
                                fontSize: [5, 6, 5],
                                color: 'white',
                                mb: [5, 5, 0],

                            }}>
                            {partnersDescription}
                        </Text>
                    </Box>
                </Box>

                <Box
                as="ul"
                    sx={{
                        flexShrink: 0,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: ['center', 'center', 'inherit'],
                        fontWeight: 400,
                        lineHeight: 1.5,
                        listStyle: ['none', 'inherit'],
                    }}>
                        {partnersTypes.map((x, i) => <Text
                        as="li"
                        key={i}
                        className="partnerstype-list"
                        sx={{
                            px: 2,
                            py: 1,
                            mb: [2, 2, 0],
                            fontSize: [4, 5, 3],
                            color: 'white',

                        }}>
                            {x.title}
                        </Text>)}
                </Box>



            </Box>
        </Box>
    )
}

export default Partners