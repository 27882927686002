import React from 'react'
import { Box, Heading, } from 'theme-ui'
import Img from "gatsby-image"
import {Link} from "gatsby"
/** @jsx jsx  */
import {jsx} from "theme-ui"

const LinkBox = ({ linksComponent }) => {
    console.log('linksComponent',linksComponent)

    const handleBoxes = (data) => data.map(x => (
        <Link
        to={x.link}
            sx={{
                display: 'flex',
                flexDirection: ['column','row'],
                position: 'relative',
                width: ['100%','50%'],
                height: ['400px','100%'],
                justifyContent: 'center',
                alignItems: 'center',
                textDecoration: 'none',
                color: 'inherit',
                '&:hover h2': {
                color: 'secondaryBlue',
                }

            }}>
            <Heading
                sx={{
                    fontSize: 6,
                    color: 'white',
                    alignItems: 'center',
                    zIndex: 2,
                  width: '80%',
                  textAlign: 'center',
                }}>
                {x.title}
            </Heading>

            <Box
                sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    '& .gatsby-image-wrapper': {
                        height: '100%'
                    }

                }}>
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        zIndex: 1,
                        bg: 'primaryBlue',
                        opacity: 0.6,

                    }}>

                </Box>
                <Img fluid={x.image.fluid} />


            </Box>


        </Link >
    ))

    return (
        <Box
            sx={{
                display: 'flex',
                position: 'relative',
                flexDirection: ['column','row'],
                width: '100%',
                height: ['100%','80vh'],
            }}>
            {handleBoxes(linksComponent)}
            <Box
                sx={{
                    display: 'flex',
                    position: 'absolute',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: '50%', 
                    left: '50%', 
                    transform:'translate(-50%,-50%)',
                    zIndex: 2,
                }}>
                <svg width="89" height="88" viewBox="0 0 89 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M30.1215 85.3961C18.8608 77.8558 24.4912 60.9655 23.955 58.251C23.4187 55.5364 17.2522 56.1397 17.2522 56.1397C14.3517 56.983 11.2853 56.7314 8.52432 55.4236C5.76331 54.1159 3.46049 51.8242 1.9698 48.901C-5.0011 35.0267 7.86825 22.359 19.6652 25.0735C19.6652 25.0735 14.8392 9.99285 23.955 3.35736C23.955 3.35736 37.0924 -8.40555 54.7878 12.1041L60.4181 17.5332C63.8632 14.3731 68.1721 12.6473 72.6172 12.6473C77.0623 12.6473 81.3713 14.3731 84.8163 17.5332C95.5408 29.2961 82.6714 45.2816 79.1859 47.6945C81.3947 48.846 83.3561 50.521 84.9358 52.6048C86.5154 54.6885 87.676 57.1318 88.3381 59.7671C89.0002 62.4025 89.148 65.1677 88.7715 67.8734C88.395 70.5791 87.5031 73.1612 86.1569 75.4429C85.1963 77.8203 83.7504 79.909 81.9368 81.539C80.1232 83.169 77.9932 84.2944 75.72 84.8234C73.4468 85.3525 71.0946 85.2704 68.855 84.5838C66.6153 83.8971 64.5514 82.6254 62.8312 80.8719C60.8774 78.6363 58.3422 77.1575 55.5921 76.6494C52.911 77.2526 42.7228 93.8413 30.1215 85.3961Z" fill="white" />
                </svg>
            </Box>


        </Box>
    )
}

export default LinkBox